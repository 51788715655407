@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');


* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Nunito Sans', sans-serif;
}

textarea {
  resize: none;
}

.signup-bg,
#banner-section {
  background-image: url('../src/assets/gif-background.gif');
  background-size: cover;
}

#donation-form-container {
  background-image: url('../src/assets/gif-background.gif');
  background-size: cover;
  min-height: 90vh;
}

/* #donation-form-container html .block.block-transparent.keela-form-image.image-fit{
 display: none;
} */
.carousel-bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.28) 100%), url('../src/assets/carousel-bg.png'), lightgray 0px -6.534px / 100% 134.356% no-repeat;
}

#main-header {
  position: fixed;
  width: 100%;
  top: 0;
}

#main-header+section {
  margin-top: 92px;
}

.custom-arrow {
  z-index: 1;
  position: absolute;
  bottom: 0;
  top: unset;
  margin-bottom: 45px;
  right: 0;
}

.next-arrow {

  margin-right: 45px;

}

.prev-arrow {
  margin-right: 149px;
}

@media (min-width: 1024px) {
  #main-header+section {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .contact-us-box {
    background: #161E2E !important;
  }
}

@media screen and (max-height: 700px) {
  .admin-logo-div {
    margin-bottom: 50px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.vertical-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.vertical-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.6));
}

.loaderImage-container {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}

.pagination ul li {
  border-radius: 24px;
  display: flex;
  width: 46.5px;
  padding: 10.25px 0px 11.25px 0px;
  justify-content: center;
  align-items: center;
  color: #161E2E;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pagination ul li.selected {
  color: #F9F9F9;
  background: #186CCE;
}

.rmsc {
  --rmsc-main: #E9EBED !important;
  /* --rmsc-hover: #0e0c0a !important;
  --rmsc-selected: #1d1915 !important; */
  --rmsc-border: #E9EBED !important;
  /* --rmsc-gray: #555555 !important; */
  --rmsc-bg: #E9EBED !important;
  color: #161E2E !important;
  --rmsc-p: 10px !important;
  /* Spacing */
  --rmsc-radius: 5px !important;
  /* Radius */
  --rmsc-h: 38px !important;
  /* Height */
}

input[type=checkbox] {
  accent-color: #186CCE;
}

.dropdown-content input {
  color: #161E2E;
}

.rmsc .dropdown-container {
  border: unset;
  background-color: unset;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: unset;
}

svg.dropdown-heading-dropdown-arrow.gray {
  fill: #161E2E;
  color: #161E2E;
  width: 20px;
}

.rmsc .dropdown-heading {
  padding: 0 !important;
}

.rmsc .dropdown-content ul {
  max-height: 180px !important;
  overflow: auto !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  font-weight: 600;
}

/* .tooltip-parent{
  position: relative;
} */
/* .tooltip-parent .tooltip{
 display: none;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
} */
/* .tooltip-parent:hover .tooltip{
  display: block;
} */
.success-message {
  position: fixed;
  bottom: 20px;
  /* Adjust the distance from the bottom as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  z-index: 99999;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  background: #F9F9F9 !important;
  box-shadow: unset !important;
  border: unset !important;
  position: unset !important;
}

.class-tab:hover ul {
  display: flex;
}

.class-tab:hover>a {
  color: #186CCE;
}

/* .keela-form .btn-form-primary, .keela-form .btn-form-primary:hover, .keela-form .btn-form-primary:focus{
  border-radius: 24px;
background-color:  #186CCE !important;
} */

/* width */
.newscrollbar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.newscrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.newscrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.newscrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chai-club td select {
  margin-left: 20px;
  background-color: #D2D6DC;
  color: #161E2E;
  font-weight: 600;
}

.chai-club td select option {
  color: #161E2E;
  font-weight: 600;
}

.draggable {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

.draggable-category {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem;

}

h2 {
  margin: 0;
}

.drag-handle {
  background: #D2D6DC;
  padding: 1rem;
  border-radius: 8px;
}

.category-container {
  width: 90%;
}

.item {
  /* border: 2px solid gray; */
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  background: #D2D6DC;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #D6D6D6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8d9294;
}

#style-3{
  mask-image: linear-gradient(to top, transparent, black),
  linear-gradient(to left, transparent 17px, black 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
  linear-gradient(to left, transparent 17px, black 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}
#style-3:hover {
  -webkit-mask-position: left top;
}
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #888;
}

.icon-parent{
  cursor: pointer;
}
.icon-parent .play-pause-icon{
  display: none;

}
.icon-parent:hover .play-pause-icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-parent:hover .music-icon{
  display: none;
}
/* .player-footer{
  transform: translateX(-50%);
} */
.swal2-confirm{
  background-color: transparent !important;
  color: #186CCE !important;
  border:  1px solid #186CCE !important;
  border-radius: 8px !important;
}
.swal2-confirm:focus{
  border:  1px solid #186CCE !important;
  box-shadow: unset !important;
}
.swal2-cancel{
  background-color: #186CCE !important;
  color: white !important;
  border:  1px solid #186CCE !important;
  border-radius: 8px !important;
}
.rhap_container{
  background-color: #1D283E !important;
  padding: unset !important;
  box-shadow: unset !important;
}
 .rhap_time {
  color: white !important;
}
.rhap_progress-filled,
.rhap_progress-indicator,
.rhap_volume-indicator
{
  background-color: #186CCE !important;
  opacity: 1 !important;
}
.rhap_progress-bar-show-download{
  background-color: white !important;
}
.rhap_forward-button{
  display: flex;
  align-items: center;
  justify-content: end;
}
.rhap_main-controls-button svg path{
  fill: white;
}
.filterMultiSelect .dropdown-heading,
.filterMultiSelect .multi-select{
background-color: #F9F9F9 !important;
  height: 56px !important;
  border-radius: 8px;
}
.filterMultiSelect .dropdown-container{
  border-radius: 8px !important; 
  background-color: #F9F9F9 !important;
  /* width: 187px; */
  box-shadow: unset !important;
 
}
.filterMultiSelect .dropdown-heading{
  padding: 0 12px !important;
}
.html-block p,.wp-block-file__embed{
  margin-bottom: 1rem;
}
.wp-block-video,.wp-block-audio{
  margin-top: 20px;
}
.wp-block-audio audio{
  width: 100%;
}
.wp-block-video video{
  width: 100%;
}
.chip {
  display: inline-block;
  padding: 4px 8px;
  margin: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f1f1f1;
  font-size: 14px;
}

.close-icon {
  margin-left: 6px;
  cursor: pointer;
}
.select-tag-item .dropdown-menu-btn{
  background-color: #E9EBED;
  height: unset;
}
.select-tag-item .dropdown-menu-btn > span{
  -webkit-box-orient:unset 
}
#banner-section a{
  font-weight: bold;
  text-decoration: underline;
  /* color: #cd2653; */
}
progress::-webkit-progress-bar {
  background-color: #ccc;
  border-radius: 7px;
}
progress::-webkit-progress-value {
  background-color: #186CCE;
  border-radius: 7px;
 
}
.tagsTypeParent input[type=checkbox] {
  opacity: 0;
}
.tagsTypeParent .infinite-scroll-component{
  height: 150px !important;
}
.subscribe-list .rmsc .dropdown-container{
  background-color: transparent;
}